.pagination {
    color: #2E446e;
    display: flex;
    flex-wrap: nowrap;
    font-weight: bold;
    padding: 10px;
    width: 100%;
    justify-content: center;
}

.page, .previous, .next {
    padding: 0 10px;
    cursor: pointer;
}

.page:hover, .previous:hover, .next:hover {
    text-decoration: underline;
}

.selected {
    color: #99CCFF;
    cursor: default;
}

.disabled {
    color: #99CCFF;
    cursor: default;
}

.selected:hover, .disabled:hover {
    text-decoration: none;
}